import { ActionReducerMap } from '@ngrx/store';
import * as fromBusiness from '../business/store/business.state';

export interface B2BAccountManagerState {
  business: fromBusiness.BusinessState;
}

export const reducers: ActionReducerMap<B2BAccountManagerState> = {
  business: fromBusiness.reducer,
};
